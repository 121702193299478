export default {
	name: 'HomeConfigDashboardPage',
	props: {
		chartsyslist: {
			type: Array,
			default: []
		},
		editData: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			dashboardConfig: [],
			// 仪表配置数据
			cmdashboardlist: [],
			refreshrateDashboard: 0,
			refreshrateChart: 0,
			selectChartShowData: [],
			chartShowTime: '1',
		}
	},
	created() {
		this.$nextTick(() => {
			if (this.editData) {
				let editData = JSON.parse(JSON.stringify(this.editData))
				this.dashboardConfig = editData.dashboardConfig
				this.selectChartShowData = editData.selectChartShowData
				this.refreshrateDashboard = editData.refreshrateDashboard
				this.refreshrateChart = editData.refreshrateChart
				this.chartShowTime = editData.chartShowTime
			}
			this.getcmdashboard()
		})

	},
	methods: {
		// 获取图表名称
		getChartDescription(type) {
			const descOpt = {
				chart1: this.$t('home.homeRcTaskTit'),
				chart2: this.$t('home.homeWoTaskTit'),
				chart3: this.$t('home.homePeIrTit'),
				chart4: this.$t('home.homeAbLocTit'),
				chart5: this.$t('home.homeHrRcTit'),
			}
			return descOpt[type] || ''
		},
		// 获取仪表配置数据
		getcmdashboard() {
			this.$http.post('/dashboard/getcmdashboard', {
				hrid: this.$store.getters.loginid,
				penum: this.$store.getters.penum,
			}).then(result => {
				if (result.data.success) {
					this.cmdashboardlist = result.data.content
				} else {
					this.$alert(result.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 提交
		saveConfigClick() {
			// console.log(this.chartShowTime)
			let dashboardcm = []
			let tempdashboardcm = {}
			this.dashboardConfig.forEach(element => {
				let tempArr = element.split('|')
				if (!tempdashboardcm[tempArr[0]]) tempdashboardcm[tempArr[0]] = []
				tempdashboardcm[tempArr[0]].push(tempArr[1])
			});
			for (let index in tempdashboardcm) {
				dashboardcm.push({
					"cmnum": index,
					"meternum": "",
					"indexcode": tempdashboardcm[index]
				})

			}

			// 仪表配置
			const setDashboardRequest = new Promise((resolve, reject) => {
				if (!this.refreshrateDashboard) this.refreshrateDashboard = '0'
				this.$http.post('/dashboard/setDashboard', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					"dashboardnum": this.editData.dashboardnum,
					"refreshrate": Math.abs(this.refreshrateDashboard) + '',
					"dashboardcm": dashboardcm

				}, {
					loadTrigger: 'pubsub',
				}).then(result => {
					if (result.data.success) {
						resolve();
					} else {
						reject(result)

					}
				})
					.catch(reason => {
						reject(reason)

					});
			})

			// 图表配置
			const setchartuserRequest = new Promise((resolve, reject) => {
				if (!this.refreshrateChart) this.refreshrateChart = '0'
				this.$http.post('/chart/setchartuser', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					"timerange": this.chartShowTime + '',
					"refreshrate": Math.abs(this.refreshrateChart) + '',
					"chart": this.selectChartShowData
				}, {
					loadTrigger: 'pubsub',
				}).then(result => {
					if (result.data.success) {
						resolve();
					} else {
						reject(result)

					}
				})
					.catch(reason => {
						reject(reason)

					});
			})

			Promise.all([setchartuserRequest, setDashboardRequest]).then(() => {
				this.$pubsub.publish('closeLoadEvent')
				// console.log(result)
				this.$emit('setSuccess', this.selectChartShowData)

				// this.chartShowData = this.selectChartShowData
				// this.getchartsyslist(() => {
				// 	this.chartlist = []
				// 	this.chartShowData.forEach((element) => {
				// 		this.getchartlist([element])
				// 	})

				// })
				// this.getDashboard()

			}).catch(reason => {
				this.$pubsub.publish('closeLoadEvent')
				if (reason.data && reason.data.message) {
					this.$alert(reason.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}

			});
		},
		cancelConfigClick() {
			this.$emit('cancelConfigClick')
		}
	}
}