<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <div class="config-wrapper">
        <div class="config-header">
          {{ $t('homeConfigDashboard.meterConfig') }}
        </div>
        <div class="config-content">
          <el-checkbox-group v-for="(item, index) in cmdashboardlist" v-model="dashboardConfig" :key="index">
            <div v-if="item.indexcode.length > 0">
              <div class="item-title">{{ item.sensorname }}：</div>
              <!-- <el-checkbox-group v-model="dashboardConfig" > -->
              <div v-for="(codeitem, codeindex) in item.indexcode" :key="index + '' + codeindex">
                <el-checkbox :label="item.cmnum + '|' + codeitem.indexcode">{{ codeitem.description }}</el-checkbox>
              </div>

              <!-- </el-checkbox-group> -->
            </div>
          </el-checkbox-group>

          <div class="number-box">
            <i18n path="homeConfigDashboard.refreshRate" :tag="false">
              <template slot="number">
                <el-input-number :step-strictly="true" style="width: 100px" v-model="refreshrateDashboard" controls-position="right" :min="0"></el-input-number>
              </template>
            </i18n>
            <span class="tips">{{ $t('homeConfigDashboard.noRefresh') }}</span>
          </div>
        </div>
      </div>

      <div class="config-wrapper">
        <div class="config-header">
          {{ $t('homeConfigDashboard.chartConfig') }}
        </div>
        <div class="config-content">
          <el-checkbox-group v-model="selectChartShowData">
            <div v-for="(chartitem, chartindex) in chartsyslist" :key="chartindex">
              <el-checkbox :label="chartitem.type">{{ getChartDescription(chartitem.type) }}</el-checkbox>
            </div>
          </el-checkbox-group>

          <div class="input-box">
            {{ $t('homeConfigDashboard.chartDisplayTime') }}：
            <el-select v-model="chartShowTime">
              <el-option value="1" :label="$t('homeConfigDashboard.nearlySevenDays')"></el-option>
              <el-option value="2" :label="$t('homeConfigDashboard.nearlyThirtyDays')"></el-option>
              <el-option value="3" :label="$t('homeConfigDashboard.nearlyNinetyDays')"></el-option>
              <el-option value="4" :label="$t('homeConfigDashboard.thisMonth')"></el-option>
              <el-option value="5" :label="$t('homeConfigDashboard.thisQuarter')"></el-option>
              <el-option value="6" :label="$t('homeConfigDashboard.lastQuarter')"></el-option>
              <el-option value="7" :label="$t('homeConfigDashboard.thisYear')"></el-option>
              <el-option value="8" :label="$t('homeConfigDashboard.prevYear')"></el-option>
            </el-select>
          </div>
          <div class="number-box">
            <i18n path="homeConfigDashboard.refreshRate" :tag="false">
              <template slot="number">
                <el-input-number :step-strictly="true" style="width: 100px" v-model="refreshrateChart" controls-position="right" :min="0"></el-input-number>
              </template>
            </i18n>
            <span class="tips">{{ $t('homeConfigDashboard.noRefresh') }}</span>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer class="footer-wrapper" height="70px">
      <el-button @click="cancelConfigClick">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="saveConfigClick()">{{ $t('common.save') }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
